/*
  全局样式
*/
/* @font-face {
  font-family: 'SF-Pro-Display';
  src: url('../../static/font/SF-Pro-Display-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
} */

html, body {
  height: 100%;
}

body {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  font-family: "SF-Pro-Display", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* #root {
  height: 100%;
} */

#root {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

input, button, select, textarea {
  outline:none;
}

textarea {
  resize:none;
}

input {
  padding: 0;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

input:-webkit-autofill {
  border: none;
  border-radius: 0px!important;
  box-shadow: 0 0 0px 1000px white inset!important;
  -webkit-box-shadow: 0 0 0px 1000px white inset!important;
  -webkit-text-fill-color: #333!important;
}

input:-webkit-autofill:hover {
  border: none!important;
}
input:-webkit-autofill:focus {
  border: none!important;
}

input::placeholder {
  color: rgba(25, 31, 37, 0.20);
  font-size: 14px;
}

/*
  动画效果
*/
